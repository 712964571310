@charset "UTF-8";
@font-face {
  font-family: EditorialNew;
  src: url("../fonts/PPEditorialNew-Ultralight.woff2");
}
h1 {
  overflow-y: hidden;
}

@keyframes idk {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.border_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #9E949C;
}

.border_top {
  position: absolute;
  z-index: 9;
  left: 0;
  width: 100%;
  height: 1px;
  background: #9E949C;
}

.border_left {
  z-index: 100;
  position: absolute;
  left: 0;
  width: 1px;
  height: 100%;
  background: #9E949C;
}

.border_right {
  z-index: 100;
  position: absolute;
  right: 0;
  width: 1px;
  height: 100%;
  background: #9E949C;
}

* {
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

* ::-webkit-scrollbar {
  display: none;
}

body {
  overscroll-behavior-y: none;
}

.overall {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100vw;
}

.blbl {
  height: 100vh;
  width: 15px;
}

.wrapper {
  /* position: absolute; */
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  /* grid-template-rows: repeat(24, 10px); */
  width: 100%;
  height: 100vh;
}

.wrapper .column {
  border: solid #9E949C 0.1px;
  grid-gap: 0;
}

.wrapper .r {
  border: solid #9E949C 0.1px;
  grid-gap: 0;
}

body {
  background: #000;
  width: 100vw;
}

.main_color {
  color: #9E949C;
}

h1 {
  font-family: EditorialNew;
  font-size: 70px;
  font-weight: 100;
  font-style: normal;
  letter-spacing: -3px;
  color: #9E949C;
}

h3 {
  font-family: EditorialNew;
  font-size: 50px;
  font-weight: 100;
  font-style: normal;
  line-height: 62px;
  letter-spacing: -0.04em;
  color: #9E949C;
}

h5 {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  line-height: 20px;
  color: #F5EFF3;
}

a {
  color: #F5EFF3;
  text-decoration: none;
  cursor: pointer;
}

.highcap {
  text-transform: uppercase;
}

.smallcap {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 13px;
  font-weight: 300;
  font-style: normal;
  line-height: 20px;
}

.text {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  line-height: 20px;
}

header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 13.48vh;
  width: 83.9vw;
}
header .logoLink {
  width: -moz-fit-content;
  width: fit-content;
}
header .logoLink .headerLogoSvg {
  margin-bottom: 2.2vh;
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
}

.mainPage {
  display: flex;
  flex-direction: row;
}

.load_container {
  background: #000;
  display: none !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 120;
}
.load_container .loader-text_container {
  display: flex;
  flex-direction: column;
  width: 20vw;
  height: auto;
  overflow: hidden;
}
.load_container .loader-text_container .loader-name_container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 20vw;
  height: 5vh;
}
.load_container .loader-text_container .loader-percent_container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 20vw;
  height: 10vh;
}
.load_container .loader-text_container .loader-percent_container .loader-percent {
  font-family: EditorialNew;
  font-size: 70px;
  font-weight: 100;
  font-style: normal;
  line-height: 60px;
  letter-spacing: -3px;
  color: #9E949C;
}
.load_container .loader-text_container .loader-percent, .load_container .loader-text_container .loader-name {
  position: absolute;
  width: auto;
  height: auto;
  top: 100%;
  opacity: 0;
  text-align: center;
}
.load_container .loader-images_container {
  position: relative;
  height: 50vh;
  width: 20vw;
  bottom: -20px;
  z-index: 110;
  overflow: hidden;
}
.load_container .loader-images_container .loader-image-f-1 {
  position: absolute;
  left: 0;
  width: 20vw;
  height: auto;
  top: 50%;
  opacity: 0;
}
.load_container .loader-images_container img[class*=loader-image-a] {
  position: absolute;
  left: 0;
  width: 20vw;
  height: auto;
  top: 0%;
  opacity: 0;
}

.container {
  position: relative;
  height: 100vh;
  width: 83.9vw;
  padding-left: 5.2vw;
}
.container .mainContent {
  display: flex;
  flex-direction: column;
}

.containerHome {
  height: 110vh;
}

.container .outImages-container {
  transition-duration: 0.3s;
  position: absolute;
  width: 100%;
  height: 100%;
}
.container .outImages-container .outImage {
  position: absolute;
  width: 120px;
  height: 162px;
  transition-duration: 0.3s;
  opacity: 20;
  aspect-ratio: 119/161;
}
.container .outImages-container :hover {
  opacity: 100;
}
.container .outImages-container :hover .outImage-image {
  transition-duration: 0.3s;
  transform: scale(1) translate(0, 0);
}
.container .outImages-container .outImage-image {
  transition-duration: 0.3s;
  width: 100%;
  transform: scale(0.7) translate(0, 50px);
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 119/161;
}
.container .outImage-pimpim {
  left: 8%;
  top: 18%;
}
.container .outImage-manga {
  left: 70%;
  top: 15%;
}
.container .outImage-food {
  left: 28%;
  top: 25%;
}
.container .outImage-pousse {
  left: 50%;
  top: 20%;
}
.container .outImage-shortlink {
  left: 7%;
  top: 50%;
}
.container .outImage-uso {
  left: 35%;
  top: 50%;
}
.container .outImage-game {
  left: -5%;
  top: 70%;
}
.container .outImage-ines {
  left: 22%;
  top: 75%;
}
.container .outImage-music {
  left: 50%;
  top: 70%;
}
.container .outImage-winter {
  left: 65%;
  top: 64%;
}
.container .outImage-book {
  left: 78%;
  top: 42%;
}

.menu {
  position: fixed;
  display: flex;
  right: 0;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  background: #000;
  transition-duration: 0.6s;
}
.menu .menu_top {
  width: 100%;
}
.menu .menu_top .menu_blank {
  position: relative;
  width: 100%;
  height: 4.44vh;
  overflow: hidden;
}
.menu .menu_top .menu_title {
  margin-top: 2.4vh;
}
.menu .menu_top .menu_title :hover {
  cursor: pointer;
}
.menu .menu_top .menu_title .menu_title-text {
  display: inline-block;
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */ /* Internet explorer depuis la version 10 et Edge */
  user-select: none; /* Propriété standard */
}
.menu .menu_top .menu_title .menu_square {
  display: inline-block;
  position: relative;
  width: 17px;
  height: 12px;
  margin-left: 16px;
  transition-duration: 0.8s;
}
.menu .menu_top .menu_items {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  margin-right: 5.2vw;
  margin-top: 9.3vh;
}
.menu .menu_top .menu_items a.menu_item {
  display: inline;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  color: #9E949C;
  margin-bottom: 1.8vh;
}
.menu .menu_top .menu_items a.menu_item h3 {
  line-height: 45px;
}

.menu.menu_close {
  width: 16.1vw;
}
.menu.menu_close .menu_title {
  text-align: right;
  margin-right: 5.2vw;
}
.menu.menu_close .menu_items {
  visibility: hidden;
}
.menu.menu_close .menu_items a.menu_item {
  transition-duration: 1s;
  opacity: 0;
}

.menu_open {
  width: 22vw;
}
.menu_open .menu_top .menu_items {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  margin-right: 5.2vw;
  margin-top: 9.3vh;
}
.menu_open .menu_top .menu_items .menu_item {
  display: inline;
  opacity: 100;
  transition-duration: 1s;
}
.menu_open .menu_top .menu_items .menu_item h3 {
  display: inline-block;
}
.menu_open .menu_top .menu_title {
  text-align: right;
  margin-right: 5.2vw;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.menu .menu_items a.menu_currentPage::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.menu .menu_items {
  /* Fade in */
}
.menu .menu_items a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200%;
  height: 0.1vh;
  background-color: #9E949C;
  opacity: 0;
  transition: opacity 300ms, transform 400ms;
}
.menu .menu_items a:hover::after,
.menu .menu_items a::after {
  opacity: 1;
  color: red;
}
.menu .menu_items a::after {
  opacity: 1;
  transform: translate3d(200%, 0, 0);
}
.menu .menu_items a:hover::after,
.menu .menu_items a:focus::after {
  transform: translate3d(0%, 0, 0);
}

.menu_footer {
  color: #F5EFF3;
  margin-bottom: 4.5vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 5.2vw;
}
.menu_footer .menu_design {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5vh;
}
.menu_footer .menu_design .ines {
  text-decoration: underline;
}
.menu_footer .menu_find {
  display: flex;
  flex-direction: column;
}
.menu_footer .find_media {
  text-align: right;
}

.home_title {
  display: inline;
  padding-top: 5px;
}

.home_arrow {
  margin: 0 1vw;
}

.arrow_link {
  margin-left: 8px;
}

.presentation_text {
  display: inline-block;
  color: #F5EFF3;
  width: 25vw;
  margin-top: 3vh;
}

.horizontal_blank1 {
  display: block;
  width: 100%;
  height: 22vh;
}

.horizontal_blank2 {
  display: block;
  width: 100%;
  height: 11vh;
}

.contact_intro {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.contact_intro .contact_text {
  display: inline;
  color: #F5EFF3;
  width: 35.7vw;
  margin-top: 4.4vh;
}

.contact_rows {
  height: 40vh;
}

.contact_mail {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 16.4vw;
  position: relative;
  cursor: pointer;
}
.contact_mail .mail {
  display: inline-block;
}
.contact_mail svg {
  height: 100%;
  margin-left: 1vw;
}
.contact_mail .contact_fill {
  display: block;
  width: 20px;
  height: 40px;
  background: #9E949C;
  margin: 0 5px;
}

.click {
  padding: 0 2px;
  cursor: pointer;
  position: absolute;
  transition: 0.5s ease-out;
  color: #F5EFF3;
  background: #000;
  z-index: 10000;
}

.not {
  display: none;
}

@media screen and (max-aspect-ratio: 4/5) {
  .presentation_text {
    width: 60vw;
  }
}
@media screen and (max-aspect-ratio: 3/4) {
  .contact_mail {
    padding-left: 0;
  }
}
@media screen and (max-aspect-ratio: 3/4) {
  .click {
    display: none;
  }
  .contact_intro .contact_text {
    width: 70vw;
  }
  .contact_mail {
    padding-left: 0;
  }
  .contact_mail span {
    display: block;
  }
}
@media screen and (max-aspect-ratio: 9/14) {
  .outImages-container {
    display: none;
  }
  div.contact_mail h1 {
    font-size: 46px;
  }
  .home_arrow {
    height: 30px;
  }
}/*# sourceMappingURL=main.css.map */