$bgColor: #000;
$mainColor: #9E949C;
$secColor: #F5EFF3;

@font-face{
	font-family: EditorialNew;
	src: url("../fonts/PPEditorialNew-Ultralight.woff2")
}

// ANIMATIONS

h1{
	overflow-y: hidden;
}

@keyframes idk {
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
}

.border_bottom{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background: $mainColor;
}

.border_top{
	position: absolute;
	z-index: 9;
	// top: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background: $mainColor;
}

.border_left{
	z-index: 100;
	position: absolute;
	// bottom: 0;
	left: 0;
	width: 1px;
	height: 100%;
	background: $mainColor;
}

.border_right{
	z-index: 100;
	position: absolute;
	// bottom: 0;
	right: 0;
	width: 1px;
	height: 100%;
	background: $mainColor;
}


// CSS

*{
	margin: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
	// color: $secColor;
}

* ::-webkit-scrollbar{
	display: none;
}

body{
	overscroll-behavior-y: none;
}

.overall{
	position: absolute;
	display: flex;
	flex-direction: row;
	width: 100vw;
}

.blbl{
	height: 100vh;
	width: 15px;
}

.wrapper{
	/* position: absolute; */
	display: grid;
	grid-template-columns: repeat(24, 1fr);
	/* grid-template-rows: repeat(24, 10px); */
	width: 100%;
	height: 100vh;
}

.wrapper .column{
	border: solid $mainColor 0.1px;
	grid-gap: 0;
}

.wrapper .r{
	border: solid $mainColor 0.1px;
	grid-gap: 0;
}
// 

body{
	background: $bgColor;
	width: 100vw;
}

.main_color{
	color: $mainColor;
}

h1{
		font-family: EditorialNew;
		// font-family: "Editor	ial New";
		font-size: 70px;
		font-weight: 100;
		font-style: normal;
		// line-height: 60px;
		letter-spacing: -3px;
		color: $mainColor;
		// animation: 1s ease-in-out 0s 1 idk;
	}
	
	h3{
		font-family: EditorialNew;
	// font-family: "Editorial New";
	font-size: 50px;
	font-weight: 100;
	font-style: normal;
	line-height: 62px;
	letter-spacing: -0.04em;
	color: $mainColor;
}

h5{
	// font-family: "Helvetica Neue";
	font-family: neue-haas-grotesk-display, sans-serif;
	font-size: 18px;
	font-weight: 300;
	font-style: normal;
	line-height: 20px;
	color: $secColor;
}

// span, div{
	// font-family: "Helvetica Neue";
	// font-family: neue-haas-grotesk-display, sans-serif;
	// font-weight: 300;
	// font-style: normal;
	// color: $secColor;
// }

a{
	color: $secColor;
	text-decoration: none;
	cursor: pointer;
}

.highcap{
	text-transform: uppercase;
}

.smallcap{
	font-family: neue-haas-grotesk-display, sans-serif;
	font-size: 13px;
	font-weight: 300;
	font-style: normal;
	line-height: 20px;
}

.text{
	font-family: neue-haas-grotesk-display, sans-serif;
	font-size: 15px;
	font-weight: 300;
	font-style: normal;
	line-height: 20px;
}

header{
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 13.48vh;
	width: 83.9vw;
	// border-bottom: 1px $mainColor solid;
	// padding-top: 4.2vw;
	.logoLink{
		width: fit-content;
		.headerLogoSvg{
			margin-bottom: 2.2vh;
			display: inline-block;
			width: fit-content;
		}
	}

}

.mainPage{
	display: flex;
	flex-direction: row;
}


.load_container{
	background: $bgColor;
	display: none !important;
	// display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	height: 100vh;
	width: 100vw;
	z-index: 120;
	.loader-text_container{
		display: flex;
		flex-direction: column;
		width: 20vw;
		height: auto;
		overflow: hidden;
		.loader-name_container{
			position: relative;
			display: flex;
			justify-content: center;
			width: 20vw;
			height: 5vh;
		}
		.loader-percent_container{
			position: relative;
			display: flex;
			justify-content: center;
			width: 20vw;
			height: 10vh;
			.loader-percent{
				font-family: EditorialNew;
				// font-family: "Editorial New";
				font-size: 70px;
				font-weight: 100;
				font-style: normal;
				line-height: 60px;
				letter-spacing: -3px;
				color: $mainColor;
			}
		}
		.loader-percent, .loader-name{
			position: absolute;
			width: auto;
			height: auto;
			top: 100%;
			opacity: 0;
			text-align: center;
		}
	}
	.loader-images_container{
		position: relative;
		height: 50vh;
		width: 20vw;
		bottom: -20px;
		z-index: 110;
		overflow: hidden;
		.loader-image-f-1{
			position: absolute;
			left: 0;
			width: 20vw;
			height: auto;
			top: 50%;
			opacity: 0;
		}
		img[class*="loader-image-a"]{
			position: absolute;
			left: 0;
			width: 20vw;
			height: auto;
			top: 0%;
			opacity: 0;
		}
	}
}


.container{
	position: relative;
	height: 100vh;
	width: 83.9vw;
	padding-left: 5.2vw;
	.mainContent{
		display: flex;
		flex-direction: column;
	}
}
.containerHome{
	height: 110vh;
}

.container{
	.outImages-container{
		transition-duration: 0.3s;
		position: absolute;
		width: 100%;
		height: 100%;
		.outImage{
			position: absolute;
			width: 120px;
			height: 162px;
			transition-duration: 0.3s;
			opacity: 20;
			// z-index: 100;
			aspect-ratio: 119/161;
		}
		:hover{
			opacity: 100;
			.outImage-image{
				transition-duration: 0.3s;
				transform: scale(1) translate(0, 0);
			}
		}
		.outImage-image{
			transition-duration: 0.3s;
			width: 100%;
			transform: scale(0.7) translate(0, 50px);
			object-fit: cover;
			aspect-ratio: 119/161;
		}
	}
	.outImage-pimpim{
		left: 8%;
		top: 18%;
	}
	.outImage-manga{
		left: 70%;
		top: 15%;
	}
	.outImage-food{
		left: 28%;
		top: 25%;
	}
	.outImage-pousse{
		left: 50%;
		top: 20%;
	}
	.outImage-shortlink{
		left: 7%;
		top: 50%;
	}
	.outImage-uso{
		left: 35%;
		top: 50%;
	}
	.outImage-game{
		left: -5%;
		top: 70%;
	}
	.outImage-ines{
		left: 22%;
		top: 75%;
	}
	.outImage-music{
		left: 50%;
		top: 70%;
	}
	.outImage-winter{
		left: 65%;
		top: 64%;
	}
	.outImage-book{
		left: 78%;
		top: 42%;
	}
}

.menu{
	position: fixed;
	display: flex;
	right: 0;
	height: 100vh;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	z-index: 1000;
	background: #000;
	transition-duration: 0.6s;
	// background: no-repeat url("../images/noir.jpg");
	.menu_top{
		width: 100%;
		.menu_blank{
			position: relative;
			width: 100%;
			height: 4.44vh;
			overflow: hidden;
		}
		.menu_title{
			margin-top: 2.4vh;
			:hover{
				cursor: pointer;
			}
			.menu_title-text{
				display: inline-block;
				-moz-user-select: none; /* Firefox */
				-webkit-user-select: none; /* Chrome, Safari, Opéra depuis la version 15 */
				-ms-user-select: none; /* Internet explorer depuis la version 10 et Edge */
				user-select: none; /* Propriété standard */
			}
			.menu_square{
				display: inline-block;
				position: relative;
				width: 17px;
				height: 12px;
				margin-left: 16px;
				transition-duration: 0.8s;
			}
		}
		.menu_items{
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			text-align: right;
			margin-right: 5.2vw;
			margin-top: 9.3vh;
			a.menu_item{
				display: inline;
				position: relative;
				height: fit-content;
				color: $mainColor;
				margin-bottom: 1.8vh;
				h3{
					line-height: 45px;
				}
			}
		}
	}
}

.menu.menu_close{
	width: 16.1vw;
	.menu_title{
		text-align: right;
		margin-right: 5.2vw;
	}
	.menu_items{
		visibility: hidden;
		a.menu_item{
			transition-duration: 1s;
			opacity: 0;
		}
	}
}

// .underline_menu{
// 	display: inline-block;
// 	width: 100%;
// 	height: 1px;
// 	background: $mainColor;
// }

.menu_open{
	width: 22vw;
	.menu_top{
		.menu_items{
			// display: block;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			text-align: right;
			margin-right: 5.2vw;
			margin-top: 9.3vh;
			.menu_item{
				display: inline;
				opacity: 100;
				transition-duration: 1s;
				h3{
					display: inline-block;
					// display: flex;
					// justify-content: flex-end;
				}
			}
		}
		.menu_title{
			text-align: right;
			margin-right: 5.2vw;
			user-select: none;
		}
	}
	.menu_footer{
		// display: flex;
		// flex-direction: column;
		// justify-content: space-around;
		// width: 100%;
		.menu_design{
			// margin-left: 3vw;
		}
		.ines{
			// margin-top: 1.5vh;
			// font-size: 12px;
		}
		.find_media{
			// margin-top: 0.8vh;
			// display: flex;
			// flex-direction: row;
			// justify-content: flex-end;
			svg{
				// margin-left: 0.5vw;
			}
		}

	}
}

.menu{
	.menu_items{
		a.menu_currentPage::after{
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}
	}	
	.menu_items{
		/* Fade in */
		a::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 200%;
			height: 0.1vh;
			background-color: $mainColor;
			opacity: 0;
			transition: opacity 300ms, transform 400ms;
		}
		a:hover::after,
		a::after {
		opacity: 1;
		// transform: translate3d(0, 2em, 0);
		color: red;
		}
		a::after {
			opacity: 1;
			transform: translate3d(200%, 0, 0);
			// color: red;
		}
		a:hover::after,
		a:focus::after{
		transform: translate3d(0%, 0, 0);
		// color: red;
		}
	}
}
.menu_footer{
	color:$secColor;
	margin-bottom: 4.5vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-right: 5.2vw;
	.menu_design{
		display: flex;
		flex-direction: column;
		margin-bottom: 2.5vh;
		.ines{
			text-decoration: underline;
		}
	}
	.menu_find{
		display: flex;
		flex-direction: column;
	}
	.find_media{
		text-align: right;
	}
}



// 

.home_title{
	display: inline;
	padding-top: 5px;
}

.home_arrow{
	margin: 0 1vw;
}

.arrow_link{
	margin-left: 8px;
}

.presentation_text{
	display: inline-block;
	color: $secColor;
	width: 25vw;
	margin-top: 3vh;
	// .word{
	// 	// display: none;
	// }
}

.horizontal_blank1{
	display: block;
	width: 100%;
	height: 22vh;
}

.horizontal_blank2{
	display: block;
	width: 100%;
	height: 11vh;
}

.contact_intro{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.contact_text{
		display: inline;
		color: $secColor;
		width: 35.7vw;
		margin-top: 4.4vh;
	}
	// .contact_rows{
	// 	.row_init{
	// 		width: 38vw;
	// 		height: 1px;
	// 		margin-bottom: 6px;
	// 	}
	// 	.row{
	// 		width: 38vw;
	// 		height: 1px;
	// 		background: $mainColor;
	// 		margin-bottom: 7px;
	// 	}
	// }
}

.contact_rows{
	height: 40vh;
}

.contact_mail{
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	padding-left: 16.4vw;
	// background: black;
	position: relative;
	cursor: pointer;
	.mail{
		display: inline-block;
	}
	svg{
		// position: absolute;
		height: 100%;
		margin-left: 1vw;
	}
	.contact_fill{
		display: block;
		width: 20px;
		height: 40px;
		background: $mainColor;
		margin: 0 5px;
	}
}

.click{
	// transition-duration: 0.5s;
	padding: 0 2px;
	cursor: pointer;
	position: absolute;
	transition: 0.5s ease-out;
	color: $secColor;
	background: #000;
	z-index: 10000;
}
.not{
	display: none;
}


// RESPONSIVE

// @media screen and (max-width: 900px) {
// 	.presentation_text{
// 		width: 60vw;
// 	}
// }
@media screen and (max-aspect-ratio: 4/5) {
	.presentation_text{
		width: 60vw;
	}
}

// @media screen and (max-width: 1100px) {
// 	.contact_mail{
// 		padding-left: 0;
// 	}
// }
@media screen and (max-aspect-ratio: 3/4) {
	.contact_mail{
		padding-left: 0;
	}
}

// @media screen and (max-width: 800px) {
// 	.contact_intro{
// 		.contact_text{
// 			width: 70vw;
// 		}
// 	}
// 	.contact_mail{
// 		padding-left: 0;
// 		span{

// 		display: block;
// 		}
// 	}
// }
@media screen and (max-aspect-ratio: 3/4) {
	.click{
		display: none;
	}
	.contact_intro{
		.contact_text{
			width: 70vw;
		}
	}
	.contact_mail{
		padding-left: 0;
		span{

			display: block;
		}
	}
}

// @media screen and (max-width: 300px) {
// 	div.contact_mail h1{
// 		font-size: 46px;
// 		// color: blue;
// 	}
// }
@media screen and (max-aspect-ratio: 9/14) {
	.outImages-container{
		display: none;
	}
	div.contact_mail h1{
		font-size: 46px;
	}
	.home_arrow{
		height: 30px;
	}
}